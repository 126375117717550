import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/weathyniveshlogo/MMP Face Logo 2.svg'
import StickyMenu from '../../lib/StickyMenu';
import Navigation from '../Navigation';

function Header({ action }) {
    useEffect(() => {
        StickyMenu();
    }, []);
    return (
        <header className="appie-header-area appie-sticky">
            <div className="container">
                <div className="header-nav-box">
                    <div className="row align-items-end">
                        <div className="col-lg-2 col-3 order-1 order-sm-1">
                            <div className="appie-logo-box wealthyniveshlogo">
                                <NavLink to="/">
                                    <img src={logo} alt="" />
                                </NavLink>
                            </div>
                        </div>
                        <div className="col-lg-9 order-3 order-sm-2 mb-10">
                            <div className="appie-header-main-menu">
                                <Navigation />
                            </div>
                        </div>
                        <div className="col-lg-1 col-9 order-2 order-sm-3 mb-10">
                            <div className="appie-btn-box">
                                {/* <a href="/login" className="login-btn">
                                    Login
                                </a> */}
                                <a
                                    // href="https://invest.mymoneypanda.in/"
                                    // href="https://play.google.com/store/apps/details?id=in.applore.mymoneypanda&pli=1"
                                    href="#download"
                                    className="main-btn ml-50"
                                    // target="blank"
                                >
                                    {/* Login */}
                                    Download
                                </a>
                                <div
                                    onClick={(e) => action(e)}
                                    className="toggle-btn ml-30 canvas_open d-lg-none d-block"
                                >
                                    <i className="fa fa-bars" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
