import React from 'react';
import appImg1 from '../../assets/images/app-img1.png';
import appImg2 from '../../assets/images/app-img2.png';
import appStore from "../../assets/images/app_store.png";
import playStore from "../../assets/images/play_store.png"

function DownloadBlock({ className }) {
    return (
        <>
            <section id="download" className={`appie-download-area ${className || ''}`}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <span>Download our app</span>
                                <br />
                                {/* <span>FOR FREE!</span> */}
                                <h3 className="appie-title">Simple and elegant</h3>
                                {/* <a href="https://play.google.com/store/apps/details?id=com.mymoneypanda.app" className="main-btn"> */}
                                <div  className="download-buttons">
                                    <a href="https://play.google.com/store/apps/details?id=in.applore.mymoneypanda&pli=1" target="_blank" rel="noopener noreferrer">
                                        <img src={playStore} alt="Download from Play Store" className="download-btn" />
                                    </a>
                                    <a href="https://apps.apple.com/us/app/my-money-panda/id6502600448" target="_blank" rel="noopener noreferrer">
                                        <img src={appStore} alt="Download from App Store" className="download-btn"/>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="appie-download-content">
                                <div className="imgBox">
                                    <img src={appImg1} className="imgOne" alt="" />
                                    <img src={appImg2} className="imgTwo" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DownloadBlock;
